<template>
  <!-- eslint-disable -->
  <div class="page px-4 px-md-4 pt-4">
    <v-row no-gutters>
      <v-col cols="12" :class="[cartDesktopOpen ? 'col-sm-8' : '']">
        <div class="d-flex px-0 px-md-4 pb-4 align-center justify-space-between">
          <span class="font-weight-bold">
            Product & Inventory
          </span>
          <div v-if="$store.state.screenWidth > 959" class="c-pointer" @click.prevent="cartDesktopOpen = !cartDesktopOpen">
            <span v-if="cartDesktopOpen" class="d-flex align-center caption-small">
              <v-icon class="mr-1" color="primary">
                mdi-cart-arrow-right
              </v-icon>
              Close Cart
            </span>
            <span v-else class="d-flex align-center caption-small">
              <v-icon class="mr-1" color="primary">
                mdi-cart
              </v-icon>
              Open Cart
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4 pl-md-4 pr-md-4">
      <v-col v-if="$roles($store.state.user.uid_roles, 'product-package')" :class="[desktopFixedCart ? 'col-md-2' : 'col-md-4']" cols="12" class="mb-4">
        <v-btn v-if="$store.state.user.role === 'pusat'" dark small depressed block color="orange darken-4" class="text-capitalize" @click.prevent="OPEN_DETAIL()">
          <v-icon class="mr-1 body-1">
            mdi-plus-circle-outline
          </v-icon>
          <span class="caption-small">Product / Package</span>
        </v-btn>
      </v-col>
      <v-col :class="[desktopFixedCart ? 'col-md-2' : 'col-md-4']" cols="12" class="px-md-4 mb-4">
        <v-btn v-if="$store.state.user.role === 'pusat'" dark small depressed block color="red darken-4" class="text-capitalize" @click.prevent="stockInventoryShow = true">
          <v-icon class="mr-1 body-1">
            mdi-text-box-check
          </v-icon>
          <span class="caption-small">Stock Check</span>
        </v-btn>
      </v-col>
      <v-col v-if="$roles($store.state.user.uid_roles, 'product-checkout-discount') && !parseInt($store.state.user.isstockist)" :class="[desktopFixedCart ? 'col-md-2' : 'col-md-4']" cols="12" class="mb-4">
        <v-btn depressed dark block color="orange darken-2" small class="text-capitalize mr-2" @click.prevent="GLOBAL_PRICING()">
          <v-icon class="mr-2 body-1">
            mdi-percent
          </v-icon>
          <span class="caption-small">Checkout Discount</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" :class="[cartDesktopOpen ? 'col-md-8' : '']" class="px-md-4">
        <v-row no-gutters>
          <v-col cols="12" class="col-md-4 pr-md-2 mb-4">
            <v-text-field
              v-model="search"
              label="Search Product"
              outlined
              block
              :name="Math.random()"
              autocomplete="off"
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
            />
          </v-col>
          <v-col cols="12" class="col-md-4 px-0 px-sm-2 mb-4">
            <v-autocomplete
              v-model.number="searchCategory"
              :items="[{ id: 0, nama: 'All Category' }].concat(categories)"
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              :name="Math.random()"
              item-value="id"
              item-text="nama"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="accent"
              label="Category"
            />
          </v-col>
          <v-col cols="12" class="col-md-4 mb-4">
            <v-select
              v-model.number="searchStatus"
              :items="[{ value: 1, name: 'Active' }, { value: 0, name: 'Not Active' }, { value: 1010, name: 'All' }]"
              item-text="name"
              item-value="value"
              :name="Math.random()"
              label="Product Status"
              outlined
              autocomplete="off"
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
            />
          </v-col>
        </v-row>
        <v-card class="px-4 pt-4 accent text-center">
          <v-data-table
            dense
            :headers="headers"
            :items="table"
            item-key="id"
            :server-items-length="tableTotal"
            :page.sync="tablePage"
            :items-per-page.sync="tableLimit"
            :options.sync="options"
            class="accent"
            :footer-props="{
              itemsPerPageOptions: [8, 15, 50, 100]
            }"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.name="{ item }">
              <div :class="[parseInt(item.status) ? '' : 'red--text']" class="d-flex flex-column align-end d-md-block caption py-2">
                <div class="caption-small font-weight-bold pb-1 d-flex align-center" style="line-height: 120%;">
                  {{ item.name }}
                  <v-icon v-if="parseInt(item.is_package)" x-small color="blue" class="ml-1">
                    mdi-package-variant-closed
                  </v-icon>
                  <v-icon v-if="parseInt(item.is_merchandise)" x-small color="purple" class="ml-1">
                    mdi-wallet-giftcard
                  </v-icon>
                  <v-icon v-if="parseInt(item.is_additional)" x-small color="orange" class="ml-1">
                    mdi-basket-plus-outline
                  </v-icon>
                </div>
                <div class="pb-1 font-weight-bold" style="font-size: 10px;line-height: 120%;">SKU : {{ item.sku || '-' }}</div>
                <div class="caption-small pb-2" style="line-height: 120%;">Category : {{ item.category_name || '-' }}</div>
                <v-img :src="$getImageAll(item.galleries)[0]" class="grey rounded" width="85" height="85" />
              </div>
            </template>
            <template v-slot:item.avg3month="{ item }">
              <div class="caption-small" style="min-width: 70px;">
                {{ parseInt(item.avg3month).toFixed(0) }} item(s)
                
              </div>
            </template>
            <template v-slot:item.stock="{ item }">
              <div v-if="!parseInt(item.is_package)" class="force-light-font py-2" style="min-width: 100px;">
                <div class="d-flex align-center justify-space-between caption" :class="[$vuetify.theme.dark ? '' : 'black--text']">
                  <div class="d-flex align-center">
                    <v-chip :class="parseInt(item.is_package) ? '' : $StockLimitColor(item.stock_limit, parseInt(item.quantity_stock))" x-small class="mr-1 px-2" />
                    {{ item.quantity_stock || 0 }} 
                    
                    
                  </div>
                  <v-btn color="purple darken-2 ml-2" dark depressed x-small class="rounded-xl text-capitalize d-flex align-center" @click.prevent="item.stock_list_stokist.length ? item.stock_list_stokist = [] : LoadProductStokist(item.id)">
                    <v-icon x-small class="caption-small mr-1">
                      {{ item.stock_list_stokist.length ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
                    </v-icon>
                    <small style="line-height: 100%;">
                      Offline Store
                    </small>
                  </v-btn>
                </div>
                <div v-if="item.stock_list_stokist.length" class="py-1">
                  <v-chip v-for="(ps, iPs) in item.stock_list_stokist" :key="'product-stock-stokist-' + item.id + '-' + iPs" x-small depressed dark color="purple lighten-1" class="rounded-xl mr-1">
                    <v-tooltip top x-small>
                      <template #activator="{ on, attrs }">
                        <span class="d-inline-block text-truncate mr-1" style="max-width: 35px;" v-bind="attrs" v-on="on">
                          {{ ps.name }} 
                          
                        </span>
                       
                      </template>
                      <span class="caption-small font-weight-bold">{{ ps.name }} - {{ ps.email }}</span>
                    </v-tooltip> = {{ (parseInt(ps.quantity_stock) || 0) }}
                  </v-chip>
                </div>
                <div class="pb-2 pb-sm-0">
                  <v-divider class="my-1" />
                  <v-btn
                    :color="$vuetify.theme.dark ? 'yellow' : 'purple'"
                    text
                    x-small
                    class="rounded-0 text-capitalize px-0 align-center d-flex"
                    style="letter-spacing: .5px;"
                    @click.prevent="ShowHistoryStock(item.id, item.name)"
                  >
                    <v-icon x-small class="mr-1">
                      mdi-history
                    </v-icon>
                    History
                  </v-btn>
                </div>
              </div>
              <div v-else class="force-light-font caption-small py-2" style="min-width: 100px;">
                <div class="green--text text-truncate" style="max-width: 175px;" v-html="item.list_packages" />
                <div class="pb-2 pb-sm-0">
                  <v-divider class="my-1" />
                  <v-btn
                    :color="$vuetify.theme.dark ? 'yellow' : 'purple'"
                    text
                    x-small
                    class="rounded-0 text-capitalize px-0 align-center d-flex"
                    style="letter-spacing: .5px;"
                    @click.prevent="ShowHistoryStock(item.id, item.name)"
                  >
                    <v-icon x-small class="mr-1">
                      mdi-history
                    </v-icon>
                    History
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-slot:item.category="{ item }">
              <div class="caption">
                {{ item.category_name }}
              </div>
            </template>
            <template v-slot:item.buy_price="{ item }">
              <div class="caption-small pb-2 pb-sm-0" style="min-width: 155px;">
                <!-- <div v-if="$store.state.user.role === 'pusat' && !$store.state.user.uid" class="d-flex fill-width justify-space-between align-center green--text text--darken-2" style="line-height: 140%;">
                  Hpp : <strong class="ml-1">{{ $BuyPrice(item, $store.state.user.role) | price }}</strong>
                </div> -->
                <!-- <div v-if="$store.state.user.role === 'pusat' && !$store.state.user.uid" class="d-flex fill-width justify-space-between align-center green--text text--darken-2" style="line-height: 140%;">
                  Hpp : <strong>{{ item.price_hpp | price }}</strong>
                </div>
                <div v-else>
                  Buy Price : -
                </div>
                <v-divider class="my-1" /> -->
                <div class="d-flex fill-width justify-space-between align-center orange--text" style="line-height: 140%;">
                  Label : <strong>{{ item.price | price }}</strong>
                </div>
                <div v-if="$SHOW_PRICING_FOR('reseller', $store.state.user.role)" class="d-flex fill-width justify-space-between align-center sell-price" style="line-height: 140%;">
                  Reseller : <strong>{{ $SellPrice(item, 'reseller') | price }}</strong>
                </div>
                <div v-if="$SHOW_PRICING_FOR('customer', $store.state.user.role)" class="d-flex fill-width justify-space-between align-center sell-price" style="line-height: 140%;">
                  Customer : <strong>{{ $SellPrice(item, 'customer') | price }}</strong>
                </div>
              </div>
            </template>
            <template v-slot:item.option="{ item }">
              <div class="d-flex flex-column pt-2 pb-2 pb-sm-2">
                <template v-if="$roles($store.state.user.uid_roles, 'product-options-cart')">
                  <v-btn
                    :disabled="(
                      parseInt(item.is_package) && cartView === 2
                        ? true
                        : (cartView === 2
                          ? (
                              (cartSyncType === 1 && cartSync === 1)
                                ? !cartBuyer
                                : ((!cartSyncType && cartSync === 1 && !VendorSelected)
                                  ? true
                                  : false
                                )
                            )
                          : ((cartView === 0 && cartBuyer)
                            ? false
                            : true
                          )
                        )
                    )"
                    dark
                    x-small
                    color="orange"
                    depressed
                    class="my-1 rounded-xl caption text-capitalize px-1"
                    @click.prevent="CART_PROCESS(null, item)"
                  >
                    <v-icon class="caption mr-1">
                      mdi-cart-plus
                    </v-icon>
                    <div class="caption-small">+ Cart</div>
                  </v-btn>
                </template>
                <!-- <v-btn v-if="!parseInt(item.is_package) && (cartView === 0 && cartBuyer) && parseInt(item.is_merchandise)" :draggable="true" dark x-small color="teal" depressed class="my-1 rounded-xl caption text-capitalize px-1" @click.prevent="CART_PROCESS(null, item, true)" @dragstart="dragMerchandise($event, item.id, item.is_package)"> -->
                <template v-if="$roles($store.state.user.uid_roles, 'product-options-merchandise')">
                  <v-btn v-if="!parseInt(item.is_package) && (cartView === 0 && cartBuyer) && parseInt(item.is_merchandise)" dark x-small color="teal" depressed class="my-1 rounded-xl caption text-capitalize px-1" @click.prevent="CART_PROCESS(null, item, true)">
                    <v-icon class="caption mr-1">
                      mdi-package-variant-closed-plus
                    </v-icon>
                    <div class="caption-small">+ Merchandise</div>
                  </v-btn>
                </template>
                <template v-if="$roles($store.state.user.uid_roles, 'product-options-additional')">
                  <v-btn v-if="!parseInt(item.is_package) && (cartView === 0 && cartBuyer) && parseInt(item.is_additional)" dark x-small color="lime darken-2" depressed class="my-1 rounded-xl caption text-capitalize px-1" @click.prevent="CART_PROCESS(null, item, false, true)">
                    <v-icon class="caption mr-1">
                      mdi-package-variant-closed-plus
                    </v-icon>
                    <div class="caption-small">+ Additional</div>
                  </v-btn>
                </template>
                <template v-if="$roles($store.state.user.uid_roles, 'product-options-pricing')">
                  <v-btn v-if="!item.is_additional" x-small color="green" dark depressed class="my-1 rounded-xl caption text-capitalize px-1" @click.prevent="OPEN_PRICING(item)">
                    <v-icon class="caption mr-1">
                      mdi-cash
                    </v-icon>
                    <div class="caption-small">Pricing</div>
                  </v-btn>
                </template>
                <template v-if="$roles($store.state.user.uid_roles, 'product-options-detail')">
                  <v-btn v-if="$store.state.user.role === 'pusat'" dark x-small color="blue" depressed class="my-1 caption rounded-xl text-capitalize px-1" @click.prevent="OPEN_DETAIL(item)">
                    <v-icon class="caption mr-1">
                      mdi-cog
                    </v-icon>
                    <div class="caption-small">Detail</div>
                  </v-btn>
                </template>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col v-if="$store.state.screenWidth > 959 && cartDesktopOpen" cols="4"class="px-md-4 cart-desktop">
        <div :class="[desktopFixedCart ? 'cart-desktop-fixed' : '']">
          <span :class="[desktopFixedCart ? 'red' : 'teal']" class="toggle-fixed">
            <v-icon dark @click.prevent="desktopFixedCart = !desktopFixedCart">
              {{ desktopFixedCart ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
            </v-icon>
          </span>
          <div class="px-0 pb-4">
            <v-btn-toggle
              v-model.number="cartView"
              tile
              color="grey"
              group
              mandatory
              style="width: 100%;"
            >
              <v-btn :value="1" d-flex class="rounded-xl text-capitalize ma-0 cart-tab buy" :class="[$store.state.user.role]" style="height: 42px!important;">
                <v-icon left class="mr-1">
                  mdi-cash
                </v-icon>
                <span class="caption">Buy</span>
              </v-btn>
              <v-btn :value="0" d-flex class="rounded-xl text-capitalize ma-0 cart-tab sell" :class="[$store.state.user.role]" style="height: 42px!important;">
                <v-icon left class="mr-1">
                  mdi-cash
                </v-icon>
                <span class="caption">Sell</span>
              </v-btn>
              <v-btn :value="2" :disabled="parseInt($store.state.user.uid) ? true : false" d-flex class="rounded-xl text-capitalize ma-0 cart-tab sync" :class="[$store.state.user.role]" style="height: 42px!important;">
                <v-icon left class="mr-1">
                  mdi-transfer
                </v-icon>
                <span class="caption">Stock</span>
              </v-btn>
            </v-btn-toggle>
          </div>
          <Cart
            v-if="$roles($store.state.user.uid_roles, 'product-sell')"
            :cart-view="cartView"
            :my-global-price="dialogGlobalPricing.data"
            :cart-buyer-selected="cartView === 2 ? stokistData.find(r => parseInt(r.id) === cartBuyer) : cartBuyerData.find(r => parseInt(r.id) === cartBuyer)"
            :cart-sync-type="cartSyncType"
            :cart-sync="cartSync"
            :desktop-fixed="true"
            @update="CART_UPDATE"
            @reload="CART_GET"
            @reload-product="PRODUCT_GET()"
            @vendor-selected="SELECT_VENDOR"
          >
            <template v-if="cartView === 2" v-slot:select-sync-type>
              <v-select
                v-model.number="cartSyncType"
                :name="Math.random()"
                :items="parseInt($store.state.user.isstockist) ? [{ value: 1, name: 'Stock Moving' }] : [{ value: 0, name: 'Buy From Vendor' }, { value: 1, name: 'Stock Moving' }]"
                item-text="name"
                item-value="value"
                label="Type"
                outlined
                dense
                hide-details
                autocomplete="off"
                class="mb-4 rounded-pill"
                @change="cartSync = 1, cartBuyer = 0, CART_GET"
              />
            </template>
            <template v-if="cartView === 2 && cartSyncType === 1" v-slot:select-sync>
              <v-select
                v-model.number="cartSync"
                :name="Math.random()"
                :items="[{ value: 1, name: 'Stock In' }, { value: 0, name: 'Stock Out' }]"
                item-text="name"
                item-value="value"
                label="Moving Type"
                outlined
                dense
                hide-details
                autocomplete="off"
                class="mb-4 rounded-pill"
                @change="cartBuyer = 0, CART_GET"
              />
            </template>
            <template v-if="cartView === 2 && cartSyncType === 1" v-slot:select-stokist>
              <v-autocomplete
                v-model.number="cartBuyer"
                :items="(cartSync ? [] : [{ id: 0, name: 'Missing (lost, damaged)' }]).concat(stokistData)"
                :name="Math.random()"
                item-value="id"
                item-text="name"
                outlined
                autocomplete="off"
                dense
                hide-details
                class="append-0 rounded-xl"
                :label="cartSync === 1 ? 'Move from :' : 'Move to :'"
              >
                <template v-slot:append-outer>
                  <v-btn :disabled="parseInt($store.state.user.isstockist) ? true : false" color="purple darken-2" depressed dark class="rounded-xl ma-0" @click.prevent="OPEN_DETAIL_MEMBER(true)">
                    <v-icon small>
                      mdi-database-plus
                    </v-icon>
                  </v-btn>
                </template>
                <template v-slot:item="data">
                  <v-card flat style="background-color: transparent;width: 100%;box-shadow: none!important;">
                    <div class="d-flex f-width align-center justify-space-between font-weight-bold text-capitalize py-1" style="font-size: 12px;">                      
                      <span>{{ data.item.name }}</span>
                      <v-chip v-if="parseInt(data.item.id)" x-small outlined color="purple" dark>Offline Store</v-chip>
                      <!-- <v-chip v-else x-small outlined color="blue" dark>Sync</v-chip> -->
                    </div>
                  </v-card>
                </template>
              </v-autocomplete>
            </template>
            <template v-if="cartView === 0" v-slot:select-buyer>
              <v-autocomplete
                v-model.number="cartBuyer"
                :items="cartBuyerData"
                :search-input.sync="cartBuyerQuery"
                :name="Math.random()"
                :filter="searchFilter"
                item-value="id"
                item-text="name"
                outlined
                autocomplete="off"
                dense
                hide-details
                class="append-0 rounded-xl"
                label="Search ..."
              >
                <template v-slot:append-outer>
                  <v-btn color="yellow darken-4" depressed dark class="rounded-xl ma-0" @click.prevent="OPEN_DETAIL_MEMBER()">
                    <v-icon small>
                      mdi-account-plus
                    </v-icon>
                  </v-btn>
                </template>
                <template v-slot:item="data">
                  <v-card flat style="background-color: transparent;width: 100%;box-shadow: none!important;">
                    <div class="mt-1 font-weight-bold d-flex fill-width justify-space-between align-center" style="font-size: 12px;">
                      {{ data.item.name }}
                      <small style="font-size: 7px;">
                        0{{ data.item.phone }}
                      </small>
                    </div>
                    <div class="text-capitalize d-flex fill-width justify-space-between align-center" style="font-size: 9px;">
                      <v-chip x-small :color="data.item.role === 'customer' ? 'teal' : (data.item.role === 'reseller' ? 'orange' : 'indigo')" dark style="font-size: 8px;">{{ data.item.role }}</v-chip> {{ $GEN_ID(data.item) }}
                    </div>
                    <v-divider class="mt-2 mb-1" light />
                  </v-card>
                </template>
              </v-autocomplete>
            </template>
            <template v-if="(cartView === 1 && $store.state.user.role !== 'pusat')" v-slot:select-seller>
              <v-select
                :value="parseInt($store.state.user.lead)"
                :items="[{ value: parseInt($store.state.user.lead), name: $store.state.user.lead_name }]"
                item-text="name"
                :name="Math.random()"
                item-value="value"
                label="Buy from:"
                outlined
                autocomplete="off"
                dense
                hide-details
                disabled
                class="mb-4"
              />
            </template>
          </Cart>
        </div>
      </v-col>
    </v-row>
    <v-btn
      v-if="$store.state.screenWidth <= 959 && $roles($store.state.user.uid_roles, 'product-sell')"
      fixed
      fab
      dark
      bottom
      right
      color="primary"
      @click.prevent="dialogCart = true"
    >
      <span v-if="$store.state.cart.length" class="cart-badge">
        {{ $store.state.cart.length }}
      </span>
      <v-icon dark>
        mdi-cart
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialogCart"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <Cart
        :cart-view="cartView"
        :my-global-price="dialogGlobalPricing.data"
        :cart-buyer-selected="cartView === 2 ? stokistData.find(r => parseInt(r.id) === cartBuyer) : cartBuyerData.find(r => parseInt(r.id) === cartBuyer)"
        :cart-sync-type="cartSyncType"
        :cart-sync="cartSync"
        :dialog="true"
        @update="CART_UPDATE"
        @reload="CART_GET"
        @reload-product="PRODUCT_GET()"
        @close-dialog="dialogCart = false"
      >
        <template v-slot:tab-header>
          <div class="px-0 pb-4" :class="[$vuetify.theme.dark ? '' : 'white']">
            <v-btn-toggle
              v-model.number="cartView"
              tile
              color="primary accent-3"
              group
              mandatory
              style="width: 100%;"
            >
              <v-btn :value="1" d-flex dark class="text-capitalize ma-0 cart-tab buy" :class="[$store.state.user.role]">
                <v-icon left class="mr-1">
                  mdi-cash
                </v-icon>
                <span class="caption">Buy</span>
              </v-btn>
              <v-btn :value="0" d-flex dark class="text-capitalize ma-0 cart-tab sell" :class="[$store.state.user.role]">
                <v-icon left class="mr-1">
                  mdi-cash
                </v-icon>
                <span class="caption">Sell</span>
              </v-btn>
              <v-btn :value="2" :disabled="parseInt($store.state.user.uid) ? true : false" d-flex dark class="text-capitalize ma-0 cart-tab sync" :class="[$store.state.user.role]">
                <v-icon left class="mr-1">
                  mdi-transfer
                </v-icon>
                <span class="caption">Stock</span>
              </v-btn>
            </v-btn-toggle>
          </div>
        </template>
        <template v-if="cartView === 2" v-slot:select-sync-type>
          <v-select
            v-model.number="cartSyncType"
            :name="Math.random()"
            :items="parseInt($store.state.user.isstockist) ? [{ value: 1, name: 'Stock Moving' }] : [{ value: 0, name: 'Buy From Vendor' }, { value: 1, name: 'Stock Moving' }]"
            item-text="name"
            item-value="value"
            label="Type"
            outlined
            dense
            hide-details
            autocomplete="off"
            class="mb-4 rounded-pill"
            @change="cartSync = 1, cartBuyer = 0, CART_GET"
          />
        </template>
        <template v-if="cartView === 2 && cartSyncType === 1" v-slot:select-sync>
          <v-select
            v-model.number="cartSync"
            :name="Math.random()"
            :items="[{ value: 1, name: 'Stock In' }, { value: 0, name: 'Stock Out' }]"
            item-text="name"
            item-value="value"
            label="Moving Type"
            outlined
            dense
            hide-details
            autocomplete="off"
            class="mb-4 rounded-pill"
            @change="cartBuyer = 0, CART_GET"
          />
        </template>
        <template v-if="cartView === 2 && cartSyncType === 1" v-slot:select-stokist>
          <v-autocomplete
            v-model.number="cartBuyer"
            :items="(cartSync ? [] : [{ id: 0, name: 'Missing (lost, damaged)' }]).concat(stokistData)"
            :name="Math.random()"
            item-value="id"
            item-text="name"
            outlined
            autocomplete="off"
            dense
            hide-details
            class="append-0 rounded-xl"
            :label="cartSync === 1 ? 'Move from :' : 'Move to :'"
          >
            <template v-slot:append-outer>
              <v-btn :disabled="parseInt($store.state.user.isstockist) ? true : false" color="purple darken-2" depressed dark class="rounded-xl ma-0" @click.prevent="OPEN_DETAIL_MEMBER(true)">
                <v-icon small>
                  mdi-database-plus
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:item="data">
              <v-card flat style="background-color: transparent;width: 100%;box-shadow: none!important;">
                <div class="d-flex f-width align-center justify-space-between font-weight-bold text-capitalize py-1" style="font-size: 12px;">
                  <span>{{ data.item.name }}</span>
                  <v-chip v-if="parseInt(data.item.id)" x-small outlined color="purple" dark>Offline Store</v-chip>
                  <!-- <v-chip v-else x-small outlined color="blue" dark>Sync</v-chip> -->
                </div>
              </v-card>
            </template>
          </v-autocomplete>
        </template>
        <template v-if="cartView === 0" v-slot:select-buyer>
          <v-autocomplete
            v-model.number="cartBuyer"
            :items="cartBuyerData"
            :search-input.sync="cartBuyerQuery"
            :name="Math.random()"
            :filter="searchFilter"
            item-value="id"
            item-text="name"
            outlined
            autocomplete="off"
            dense
            hide-details
            class="append-0 rounded-xl"
            label="Search ..."
          >
            <template v-slot:append-outer>
              <v-btn color="yellow darken-4" depressed dark class="rounded-xl ma-0" @click.prevent="OPEN_DETAIL_MEMBER()">
                <v-icon small>
                  mdi-account-plus
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:item="data">
              <v-card flat style="background-color: transparent;width: 100%;box-shadow: none!important;">
                <div class="mt-1 font-weight-bold d-flex fill-width justify-space-between align-center" style="font-size: 12px;">
                  {{ data.item.name }}
                  <small style="font-size: 7px;">
                    0{{ data.item.phone }}
                  </small>
                </div>
                <div class="text-capitalize d-flex fill-width justify-space-between align-center" style="font-size: 9px;">
                  <v-chip x-small :color="data.item.role === 'customer' ? 'teal' : (data.item.role === 'reseller' ? 'orange' : 'indigo')" dark style="font-size: 8px;">
                    {{ data.item.role }}
                  </v-chip> {{ $GEN_ID(data.item) }}
                </div>
                <v-divider class="mt-2 mb-1" light />
              </v-card>
            </template>
          </v-autocomplete>
        </template>
        <template v-if="(cartView === 1 && $store.state.user.role !== 'pusat')" v-slot:select-seller>
          <v-select
            :value="parseInt($store.state.user.lead)"
            :items="[{ value: parseInt($store.state.user.lead), name: $store.state.user.lead_name }]"
            item-text="name"
            :name="Math.random()"
            item-value="value"
            label="Buy from:"
            outlined
            autocomplete="off"
            dense
            hide-details
            disabled
            class="mb-4"
          />
        </template>
      </Cart>
      <!-- <Cart :cart-view="cartView" :cart-sync="cartSync" :dialog="true" @update="CART_UPDATE" @reload="CART_GET" @reload-product="PRODUCT_GET()" @close-dialog="dialogCart = false" /> -->
    </v-dialog>
    <v-dialog
      v-model="newProductCategory.show"
      max-width="450"
      scrollable
      light
    >
      <v-card v-if="newProductCategory.data">
        <v-card-title
          class="caption justify-center py-3 subtitle-1"
        >
          {{ newProduct.data.id ? 'Update Category' : 'Create Category' }}
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="newProductCategory.show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div class="px-2 pt-8 pb-0 image-galleries">
            <v-img
              :src="newProductCategory.data.foto || '/images/no-image.jpg'"
              aspect-ratio="1"
              class="grey lighten-2"
              @click.prevent="UPLOAD_IMAGE('category')"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
          <div class="px-2 pt-8 pb-0">
            <v-text-field
              v-model="newProductCategory.data.nama"
              label="Category Name"
              :name="Math.random()"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
            />
          </div>
          <div class="px-2 pb-0">
            <v-select
              v-model="newProductCategory.data.status"
              :items="[{ value: 'aktif', name: 'Active' }, { value: 'tidak aktif', name: 'No Active' }]"
              item-text="name"
              item-value="value"
              :name="Math.random()"
              label="Status"
              autocomplete="off"
              outlined
              dense
              hide-details
              class="mb-4"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end px-8 pb-4">
          <v-spacer />
          <v-btn
            depressed
            color="primary"
            small
            class="text-capitalize"
            @click="PRODUCT_CATEGORY_PROCESS()"
          >
            {{ newProductCategory.data.id ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newProduct.show"
      max-width="450"
      scrollable
      light
    >
      <v-card v-if="newProduct.data">
        <v-card-title
          class="caption justify-center py-3 subtitle-1"
        >
          <span v-if="newProduct.data.is_package">
            {{ newProduct.data.id ? 'Update Package' : 'Create Package' }}
          </span>
          <span v-else>
            {{ newProduct.data.id ? 'Update Product' : 'Create Product' }}
          </span>
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="newProduct.show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div class="px-2 pt-8 pb-0 image-galleries">
            <v-img
              src="/images/no-image.jpg"
              aspect-ratio="1"
              class="grey lighten-2"
              @click.prevent="UPLOAD_IMAGE()"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-img
              v-for="(img, iImg) in newProduct.data.galleries"
              :key="'gallery-' + iImg"
              :src="img"
              :lazy-src="img"
              aspect-ratio="1"
              class="grey lighten-2"
              @click.prevent="UPLOAD_IMAGE(iImg)"
            >
              <div class="remove-gallery" @click.stop>
                <div @click.prevent="newProduct.data.galleries.splice(iImg, 1)">
                  x
                </div>
              </div>
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
          <div class="px-2 pt-8 pb-0">
            <v-text-field
              v-model="newProduct.data.sku"
              :name="Math.random()"
              label="SKU"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
            />
            <v-select
              v-model.number="newProduct.data.is_package"
              :items="[{ value: 0, name: 'None' }, { value: 1, name: 'Yes' }]"
              item-text="name"
              item-value="value"
              :name="Math.random()"
              label="Is Package ?"
              autocomplete="off"
              outlined
              dense
              hide-details
              class="mb-4"
              @change="newProduct.data.price_hpp = 0"
            />
            <v-select
              v-if="!newProduct.data.is_package"
              v-model.number="newProduct.data.is_additional"
              :items="[{ value: 0, name: 'None' }, { value: 1, name: 'Yes' }]"
              item-text="name"
              item-value="value"
              :name="Math.random()"
              label="Additional Packaging?"
              autocomplete="off"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-select
              v-if="!newProduct.data.is_package"
              v-model.number="newProduct.data.is_merchandise"
              :items="[{ value: 0, name: 'None' }, { value: 1, name: 'Yes' }]"
              item-text="name"
              item-value="value"
              :name="Math.random()"
              label="Can be used as merchandise?"
              autocomplete="off"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-text-field
              v-model="newProduct.data.name"
              :label="parseInt(newProduct.data.is_package) ? 'Package Name' : 'Product Name'"
              :name="Math.random()"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
            />
            <div v-if="!newProduct.data.is_additional" class="d-flex">
              <v-autocomplete
                v-model.number="newProduct.data.category"
                :items="categories"
                :name="Math.random()"
                item-value="id"
                item-text="nama"
                outlined
                dense
                hide-details
                autocomplete="off"
                label="Category"
                class="mb-4"
              ></v-autocomplete>
              <v-btn depressed class="mx-1" :disabled="!parseInt(newProduct.data.category)" style="height: 40px!important;" @click.prevent="OPEN_CATEGORY(parseInt(newProduct.data.category))">
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn depressed style="height: 40px!important;" @click.prevent="OPEN_CATEGORY()">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </div>
            <fieldset v-if="parseInt(newProduct.data.is_package)" class="px-4 rounded-lg mb-4 pt-2 pb-4">
              <legend class="caption-small font-weight-bold">
                <span class="d-flex px-1">
                  Product Package List
                </span>
              </legend>
              <ProductSearch v-model="newProduct.packageListSelected" />
              <div class="grey lighten-3 rounded-lg mt-2 px-2 py-1">
                <div v-if="newProduct.packageList.length">
                  <template v-for="(pklst, ipklst) in newProduct.packageList">
                    <div v-if="!pklst.removed" :key="'package-product-' + ipklst" class="d-flex fill-width align-center justify-space-between px-4 py-2 my-1 caption-small white rounded-pill">
                      <strong style="line-height: 140%;">
                        {{ pklst.name }}
                      </strong>
                      <div class="mx-1 d-flex align-center grey lighten-4 rounded-pill" style="max-width: 100px;">
                        <v-btn small icon depressed color="transparent" @click.prevent="decreaseQuantity(ipklst)">
                          <v-icon class="orange--text caption">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <input v-model.number="newProduct.packageList[ipklst].quantity" type="text" class="text-center" style="width: 20px;outline: none;" @keypress="$NumberOnly">
                        <v-btn small icon depressed color="transparent" @click.prevent="increaseQuantity(ipklst)">
                          <v-icon class="orange--text caption">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </div>
                      <v-btn icon x-small color="red" @click.prevent="RemovePackageList(ipklst)">
                        <v-icon small>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </div>
                <div v-else class="text-center px-4 py-2 caption-small red--text">
                  No product selected
                </div>
              </div>
            </fieldset>
            <v-textarea
              v-model="newProduct.data.description"
              rows="2"
              label="Description"
              :name="Math.random()"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
            />
            <v-textarea
              v-if="!newProduct.data.is_additional"
              v-model="newProduct.data.how_to_use"
              rows="2"
              label="How to use?"
              :name="Math.random()"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
            />
            <v-text-field
              v-model.number="newProduct.data.weight"
              label="Weight"
              outlined
              :name="Math.random()"
              suffix="gram"
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
              @keypress="$NumberOnly"
            />
            <template
              v-if="($store.state.user.uid ? $roles($store.state.user.uid_roles, 'finance') : true)"
            >
              <Currency
                v-if="!newProduct.data.is_package"
                label="Hpp Price"
                v-model.number="newProduct.data.price_hpp"
              />
            </template>
            <Currency
              label="Label Price"
              v-model.number="newProduct.data.price"
            />
            <!-- <v-text-field
              v-if="!newProduct.data.is_package"
              v-model.number="newProduct.data.price_hpp"
              label="Hpp Price"
              outlined
              :name="Math.random()"
              prefix="Rp"
              dense
              hide-details
              class="mb-4"
              autocomplete="off"
              @keypress="$NumberOnly"
            />
            <v-text-field
              v-model.number="newProduct.data.price"
              label="Label Price"
              outlined
              :name="Math.random()"
              prefix="Rp"
              dense
              hide-details
              class="mb-4"
              autocomplete="off"
              @keypress="$NumberOnly"
            /> -->
            <v-text-field
              v-model.number="newProduct.data.stock_limit"
              label="Stock Limit"
              outlined
              :name="Math.random()"
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
              @keypress="$NumberOnly"
            />
            <v-select
              v-model.number="newProduct.data.status"
              :items="[{ value: 1, name: 'Active' }, { value: 0, name: 'No Active' }]"
              item-text="name"
              item-value="value"
              :name="Math.random()"
              label="Status"
              autocomplete="off"
              outlined
              dense
              hide-details
              class="mb-4"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end px-8">
          <!-- <v-btn
            v-if="newProduct.data.id && parseInt(newProduct.data.status) === 0"
            text
            color="red"
            small
            class="text-capitalize"
            @click="PRODUCT_PROCESS(true)"
          >
            Remove
          </v-btn> -->
          <v-spacer />
          <!-- :disabled="!(newProduct.data.name && parseInt(newProduct.data.weight) && parseInt(newProduct.data.price_hpp))" -->
          <v-btn
            depressed
            color="blue"
            small
            dark
            class="rounded-xl text-capitalize px-6"
            @click="PRODUCT_PROCESS()"
          >
            {{ newProduct.data.id ? 'Update' : 'Create' }} {{ parseInt(newProduct.data.is_package) ? 'Package' : 'Product' }}
          </v-btn>
          <v-spacer v-if="!(newProduct.data.id && parseInt(newProduct.data.status) === 0)" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPricing.show"
      max-width="450"
      scrollable
    >
      <v-card v-if="dialogPricing.data">
        <v-card-title
          class="primary white--text py-3 flex-column justify-start subtitle-2"
        >
          <div class="d-flex" style="width: 100%;">
            Pricing Options
            <v-spacer />
          </div>
          <div class="d-flex body-2" style="width: 100%;">
            Set pricing per-Item - {{ dialogPricing.productName }}
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <div class="px-0 pt-2 pb-0">
            <v-card flat class="shadow-none">
              <v-card-text class="text-center">
                <v-chip class="px-4">
                  <span class="mr-1 text-capitalize">Label Price</span>:<strong class="ml-1">{{ dialogPricing.productPrice | price }}</strong>
                </v-chip>
              </v-card-text>
              <v-card-text class="py-0 px-0">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <!-- <Currency
                      v-if="$SHOW_PRICING_FOR('distributor', $store.state.user.role)"
                      v-model.number="dialogPricing.data.price_distributor"
                      label="Distributor Price"
                    />
                    <Currency
                      v-if="$SHOW_PRICING_FOR('agen', $store.state.user.role)"
                      v-model.number="dialogPricing.data.price_agen"
                      label="Agen Price"
                    /> -->
                    <Currency
                      v-if="$SHOW_PRICING_FOR('reseller', $store.state.user.role)"
                      v-model.number="dialogPricing.data.price_reseller"
                      label="Reseller Price"
                    />
                    <Currency
                      v-if="$SHOW_PRICING_FOR('customer', $store.state.user.role)"
                      v-model.number="dialogPricing.data.price_customer"
                      label="Customer Price"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end px-8 pb-6">
          <v-btn
            outlined
            color="red"
            small
            class="text-capitalize"
            @click="dialogPricing.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            small
            depressed
            class="text-capitalize"
            :disabled="loadingPricing"
            @click="PROCESS_PRICING()"
          >
            Update Pricing
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogGlobalPricing.show"
      max-width="550"
      scrollable
    >
      <v-card>
        <v-card-title
          class="primary white--text py-3 body-2 mb-1 font-weight-bold"
        >
          Checkout Discount Setup
        </v-card-title>
        <v-card-subtitle
          class="caption primary white--text"
        >
          Setup Discount for Total Amount while Checkout
        </v-card-subtitle>
        <v-card-text class="py-4">
          <v-row justify="center">
            <v-expansion-panels v-model.number="expandDiscount" flat class="expand-content">
              <!-- <Discount title="Distributor Discount" :data="[].concat(dialogGlobalPricing.data.filter(r => r.for_role === 'distributor'))" :show="expandDiscount === 0" role="distributor" @update="SYNC_DATA_DISCOUNT" />
              <Discount title="Agen Discount" :data="[].concat(dialogGlobalPricing.data.filter(r => r.for_role === 'agen'))" :show="expandDiscount === 1" role="agen" @update="SYNC_DATA_DISCOUNT" /> -->
              <Discount title="Reseller Discount" :data="[].concat(dialogGlobalPricing.data.filter(r => r.for_role === 'reseller'))" :show="expandDiscount === 0" role="reseller" @update="SYNC_DATA_DISCOUNT" />
              <Discount title="Customer Discount" :data="[].concat(dialogGlobalPricing.data.filter(r => r.for_role === 'customer'))" :show="expandDiscount === 1" role="customer" @update="SYNC_DATA_DISCOUNT" />
            </v-expansion-panels>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end px-8 pb-6">
          <v-btn
            outlined
            color="red"
            small
            class="text-capitalize"
            @click="dialogGlobalPricing.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            depressed
            color="primary"
            small
            class="text-capitalize px-4"
            @click="GLOBAL_PRICING_ADD_BULK()"
          >
            Save Discount
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newMember.show"
      max-width="450"
      scrollable
    >
      <v-card v-if="newMember.data" light class="white">
        <v-card-title
          class="caption justify-center py-3 subtitle-1 text-capitalize"
        >
          {{ newMember.data.id ? 'Update' : 'Create New' }} {{ newMember.data.role === 'pusat' ? 'Stokist' : newMember.data.role }}
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="newMember.show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div class="px-2 pt-8 pb-0">
            <v-select
              v-model="newMember.data.role"
              :name="Math.random()"
              :items="$store.state.user.role === 'pusat' ? newMember.data.role === 'pusat' ? [
                { value: 'pusat', name: 'Stokist' }
              ] : [
                { value: 'customer', name: 'Customer' },
                { value: 'reseller', name: 'Reseller' }
              ] : []"
              item-text="name"
              autocomplete="off"
              item-value="value"
              label="User Type"
              outlined
              dense
              hide-details
              class="mb-4 required"
            />
            <!-- <v-autocomplete
              v-if="$store.state.user.role === 'pusat'"
              :name="Math.random()"
              autocomplete="off"
              v-model.number="newMember.data.lead"
              :search-input.sync="searchLead"
              :items="itemsLeads"
              item-value="id"
              item-text="name"
              outlined
              dense
              hide-details
              label="Select Lead"
              @click="searchLead = ''"
              class="mb-4 required"
            >
              <template v-slot:item="data">
                <v-card class="d-flex justify-space-between" flat style="background-color: transparent;width: 100%;box-shadow: none!important;">
                  {{ data.item.name }}
                  <v-spacer />
                  <v-chip small>
                    {{ data.item.role }}
                  </v-chip>
                </v-card>
              </template>
            </v-autocomplete> -->
            <v-text-field
              v-model="newMember.data.name"
              :name="Math.random()"
              label="Name"
              autocomplete="disabled"
              outlined
              dense
              hide-details
              class="mb-4 required"
            />
            <v-text-field
              v-if="($store.state.user.role === 'pusat' && newMember.data.role !== 'customer' && newMember.data.role !== 'pusat')"
              v-model="newMember.data.area_name"
              :name="Math.random()"
              label="Area Name"
              autocomplete="disabled"
              outlined
              dense
              hide-details
              class="mb-4 required"
            />
            <v-menu
              v-if="newMember.data.role !== 'customer' && newMember.data.role !== 'pusat'"
              ref="menuBirthday"
              v-model="menuBirthday"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newMember.data.birthday"
                  label="Birthday date"
                  readonly
                  outlined
                  dense
                  hide-details
                  autocomplete="disabled"
                  class="mb-4"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="pickerBirthday"
                v-model="newMember.data.birthday"
                :max="new Date().toISOString().substr(0, 10)"
                min="1970-01-01"
                @change="$refs.menuBirthday ? $refs.menuBirthday.save(newMember.data.birthday) : false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-if="newMember.data.role !== 'customer'"
              v-model="newMember.data.username"
              :name="Math.random()"
              label="Username"
              autocomplete="disabled"
              outlined
              dense
              hide-details
              class="mb-4 required"
              @keyup="newMember.data.username = newMember.data.username ? newMember.data.username.toLowerCase().split(' ').join('') : ''"
            />
            <v-text-field
              v-if="newMember.data.role !== 'customer'"
              v-model="newMember.data.email"
              :name="Math.random()"
              autocomplete="disabled"
              type="email"
              label="Email"
              outlined
              dense
              hide-details
              class="mb-4"
              :class="[newMember.data.role === 'customer' ? '' : 'required']"
              :rules="[newMember.data.role !== 'customer' ? $validateEmail(newMember.data.email) : true]"
            />
            <v-text-field
              v-model="newMember.data.phone"
              :name="Math.random()"
              label="Phone / Whatsapp Number"
              autocomplete="disabled"
              outlined
              dense
              hide-details
              class="mb-4 required"
              @keypress="$NumberOnly"
              @keyup="newMember.data.phone = $reformatPhone(newMember.data.phone)"
            />
            <v-text-field
              v-if="newMember.data.role !== 'customer' && newMember.data.role !== 'pusat'"
              v-model="newMember.data.instagram"
              :name="Math.random()"
              autocomplete="disabled"
              label="Instagram Username"
              prefix="@"
              outlined
              dense
              hide-details
              :class="[newMember.data.role === 'customer' ? '' : 'required']"
              class="mb-4"
            />
            <v-autocomplete
              v-model.number="newMember.data.area"
              :name="Math.random()"
              :search-input.sync="areaquery"
              :items="areas"
              item-value="id"
              item-text="area_name"
              outlined
              dense
              hide-details
              label="District, City, Province (Shipping Origin)"
              class="mb-4"
              autocomplete="disabled"
              @change="areaSync()"
            ></v-autocomplete>
            <v-textarea
              v-model="newMember.data.address"
              :name="Math.random()"
              autocomplete="disabled"
              rows="2"
              label="Full Address"
              outlined
              dense
              hide-details
              class="mb-4 required"
            />
            <!-- <v-textarea
              v-model="newMember.data.notes"
              :name="Math.random()"
              autocomplete="disabled"
              rows="2"
              label="Notes"
              outlined
              dense
              hide-details
              class="mb-4"
            /> -->
            <label v-if="newMember.data.role !== 'customer' && newMember.data.password" class="caption">
              Password : {{
                $passwordCheck(newMember.data.password) <= 25
                ? 'weak'
                  : $passwordCheck(newMember.data.password) <= 50
                  ? 'weak'
                    : $passwordCheck(newMember.data.password) <= 75
                    ? 'medium'
                      : $passwordCheck(newMember.data.password) <= 100
                      ? 'strong' : '-'
              }}
            </label>
            <v-progress-linear
              v-if="newMember.data.role !== 'customer' && newMember.data.password"
              :value="$passwordCheck(newMember.data.password)"
              :color="
                $passwordCheck(newMember.data.password) <= 25
                ? 'red'
                  : $passwordCheck(newMember.data.password) <= 50
                  ? 'orange'
                    : $passwordCheck(newMember.data.password) <= 75
                    ? 'lime'
                      : $passwordCheck(newMember.data.password) <= 100
                      ? 'green' : 'grey'
              "
              class="mb-4"
            />
            <v-text-field
              v-if="newMember.data.role !== 'customer'"
              :name="Math.random()"
              autocomplete="new-password"
              v-model="newMember.data.password"
              :label="newMember.data.id ? 'Change Password' : 'New Password'"
              outlined
              dense
              hide-details
              class="mb-4 required"
              :type="togglePassword ? 'text' : 'password'"
              :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePassword = !togglePassword"
            />
            <v-text-field
              v-if="newMember.data.role !== 'customer'"
              :name="Math.random()"
              autocomplete="new-password"
              v-model="newMember.data.password_confirm"
              :label="newMember.data.id ? 'Confirm Password' : 'Confirm Password'"
              outlined
              dense
              hide-details
              class="mb-4 required"
              :type="togglePassword ? 'text' : 'password'"
              :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="togglePassword = !togglePassword"
            />
            <v-select
              v-if="$store.state.user.role === 'pusat' && newMember.data.role !== 'pusat' && newMember.data.role !== 'customer'"
              v-model.number="newMember.data.show_web"
              :name="Math.random()"
              autocomplete="disabled"
              :items="[
                { value: 0, name: 'None' },
                { value: 1, name: 'Yes' }
              ]" 
              item-text="name"
              item-value="value"
              label="Show on the web member List"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-select
              v-if="newMember.data.role !== 'customer'"
              v-model.number="newMember.data.status"
              :name="Math.random()"
              autocomplete="disabled"
              :items="[
                { value: 0, name: 'Not active' },
                { value: 1, name: 'Active' }
              ]" 
              item-text="name"
              item-value="value"
              label="Member Status"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-textarea
              v-if="newMember.data.role !== 'customer'"
              v-model="newMember.data.invoice_note"
              :name="Math.random()"
              rows="2"
              label="Invoice Notes"
              outlined
              dense
              hide-details
              autocomplete="disabled"
              class="mb-4"
            />
            <v-divider class="mb-4" />
            <v-textarea
              v-if="$store.state.user.role === 'pusat'"
              v-model="newMember.data.wa_message"
              :name="Math.random()"
              autocomplete="disabled"
              rows="2"
              label="Whatsapp Message for Web"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-textarea
              v-if="$store.state.user.role === 'pusat'"
              v-model="newMember.data.notes_admin"
              :name="Math.random()"
              autocomplete="disabled"
              rows="2"
              label="Notes (pusat only)"
              outlined
              dense
              hide-details
              class="mb-4"
            />
            <v-select
              v-if="!parseInt($store.state.user.uid) && newMember.data.role !== 'pusat'"
              v-model="newMember.data.joined_source"
              label="User Source"
              :items="[{ id: '', name: 'All Source' }].concat($store.state.trxSource)"
              item-value="id"
              item-text="name"
              block
              outlined
              dense
              hide-details
              class="mb-4"
              autocomplete="off"
              @change="newMember.data.joined_admin = 0"
            />
            <v-select
              v-if="!parseInt($store.state.user.uid) && newMember.data.role !== 'pusat'"
              v-model.number="newMember.data.joined_admin"
              label="Select Admin"
              :items="[{ id: 0, name: 'None' }].concat($store.state.user_admin)"
              :item-text="(v) => {
                return v.name + (v.master_name ? ' (' + v.master_name + ')' : '')
              }"
              item-value="id"
              block
              outlined
              dense
              hide-details
              class="mb-4"
              autocomplete="off"
            />
            <v-menu
              v-if="!parseInt($store.state.user.uid) && newMember.data.role !== 'pusat'"
              ref="joinedMenu"
              v-model="joinedMenu"
              :close-on-content-click="false"
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newMember.data.joined"
                  label="Joined Date"
                  hide-details
                  outlined
                  dense
                  persistent-hint
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newMember.data.joined"
                no-title
                @input="$refs.joinedMenu ? $refs.joinedMenu.save(newMember.data.joined) : false, joinedMenu = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center px-8 pt-4 pb-2">
          <v-btn
            depressed
            color="orange darken-4"
            dark
            class="px-8 rounded-xl text-capitalize"
            small
            @click="MEMBER_PROCESS()"
          >
            {{ newMember.data.id ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <StocksInventory :show="stockInventoryShow" @close="stockInventoryShow = false" @show-history="ShowHistoryStock" />
    <v-dialog
      v-model="StockHistory.show"
      max-width="750"
      scrollable
      light
    >
      <v-card>
        <v-card-title
          class="caption py-3 subtitle-1"
        >
          Stock History : <strong class="ml-2 text-truncate" style="max-width: 200px;">{{ StockHistory.title }}</strong>
          <v-btn
            icon
            color="red"
            class="text-capitalize"
            small
            style="position: absolute;right: 10px;top: 10px;"
            @click="StockHistory.show = false, StockHistory.data = []"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-4 pt-4">
          <v-row no-gutters>
            <v-col cols="6" class="col-sm-6 pr-1">
              <v-select
                v-model.number="historyUserStokist"
                :disabled="parseInt($store.state.user.isstockist) ? true : false"
                label="Select Source"
                :items="[{ id: 0, user_name: 'All' }].concat($store.state.stokist)"
                item-value="id"
                item-text="user_name"
                block
                outlined
                dense
                hide-details
                class="mb-2"
                color="primary"
                autocomplete="off"
              />
            </v-col>
            <v-col cols="6" class="col-sm-6 pl-1">
              <v-menu
                ref="historyMenu"
                v-model="historyMenu"
                :return-value.sync="historyDates"
                left
                offset-y
                :close-on-content-click="false"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="historyDateRangeText"
                    label="Date Ranges"
                    append-icon="mdi-calendar-outline"
                    readonly
                    outlined
                    dense
                    hide-details
                    clearable
                    light
                    color="primary"
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="historyDates = []"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="historyDates"
                  no-title
                  light
                  class="force-light-font"
                  range
                >
                  <v-spacer />
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="historyMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="$refs.historyMenu.save(historyDates)"
                  >
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="col-sm-6 pr-1">
              <v-select
                v-model="historySource"
                label="Order Source"
                :items="[{ id: '', name: 'All Source' }].concat($store.state.trxSource)"
                color="primary"
                item-value="id"
                item-text="name"
                block
                outlined
                dense
                light
                hide-details
                autocomplete="off"
              />
            </v-col>
            <v-col cols="6" class="col-sm-6 pl-1">
              <v-select
                v-model="historyUid"
                label="Select Admin"
                :items="[{ id: null, name: 'All' }].concat($store.state.user_admin)"
                :item-text="(v) => {
                  return v.name + (v.master_name ? ' (' + v.master_name + ')' : '')
                }"
                item-value="id"
                light
                block
                outlined
                dense
                hide-details
                autocomplete="off"
              />
            </v-col>
            <v-col cols="12" class="d-flex justify-center pt-4">
              <v-btn-toggle
              v-model="StockHistory.trxOnly"
              borderless
              small
              
              >
              <v-btn :value="true" >
                <span class="hidden-sm-and-down">Trx History</span>
                <v-icon right>
                  mdi-format-align-left
                </v-icon>
              </v-btn>
              <v-btn :value="false">
                <span class="hidden-sm-and-down">Stock History</span>
                <v-icon right>
                  mdi-format-align-center
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" class="d-flex justify-center pt-4" v-if="!StockHistory.trxOnly">
            <v-btn-toggle
            v-model="StockHistory.vendorOnly"
            borderless
            small
            
            >
            <v-btn :value="false">
              <span class="hidden-sm-and-down">Stock In / Out</span>
              
            </v-btn>
            <v-btn :value="true" >
              <span class="hidden-sm-and-down">Vendor Purchase</span>
              
            </v-btn>
          </v-btn-toggle>

            </v-col>
          </v-row>
          <div v-if="!StockHistory.data.length" class="text-center pa-4 caption-small red--text">
            No data
          </div>
          <div v-for="(sh, iSh) in StockHistory.data" :key="'stock-history-' + iSh" class="d-flex justify-space-between align-center fill-width pa-4 grey lighten-4 my-2 rounded-lg caption-small">
            <div class="d-flex flex-column text-uppercase f-width" style="line-height: 140%;">
              <span>
                <!-- #{{ (sh.refid || sh.trx) + ' - ' + $localDT(sh.created, 'datelocal') }} : -->
                {{ (sh.refid ? sh.refid : ('#' + sh.trx)) }}
              </span>
              <div class="d-flex align-center pt-1 f-width">
                <v-chip x-small :color="parseInt(sh.buyer) === parseInt($store.state.user.id) ? 'blue' : 'red'" outlined dark class="mr-1">
                  <v-icon x-small>
                    {{ parseInt(sh.buyer) === parseInt($store.state.user.id) ? 'mdi-clipboard-arrow-left' : 'mdi-clipboard-arrow-right' }}
                  </v-icon>
                </v-chip>
                <span v-if="parseInt(sh.sync) && sh.seller_name && sh.buyer_name" class="ml-1 font-weight-bold">
                  <!-- SYNC BOTH SELLER & BUYER -->
                  {{ (parseInt(sh.buyer) === parseInt($store.state.user.id) ? ('STOCK IN FROM "' + (sh.seller_name || '')) : ('STOCK OUT TO "' + (sh.buyer_name || ''))) + '" = ' + (parseInt(sh.buyer) === parseInt($store.state.user.id) ? '+' : '-') + parseInt(sh.quantity_total) }} qty
                </span>
                <div v-else-if="parseInt(sh.sync)" class="ml-1 font-weight-bold">
                  <!-- SYNC SELLER OR BUYER -->
                  <span v-if="sh.seller_name_vendor">
                    STOCK SYNC IN [VENDOR PURCHASE] = +{{ parseInt(sh.quantity_total) }} qty
                  </span>
                  <!-- <span v-if="sh.seller_name_vendor">
                    STOCK SYNC IN [VENDOR PURCHASE] = +{{ parseInt(sh.quantity_total_purchase_completed) }} qty
                  </span> -->
                  <span v-else>
                    {{ (parseInt(sh.buyer) === parseInt($store.state.user.id) ? ('STOCK SYNC IN') : ('STOCK SYNC OUT')) + ' = ' + (parseInt(sh.buyer) === parseInt($store.state.user.id) ? '+' : '-') + parseInt(sh.quantity_total) }} qty
                  </span>
                  <div class="font-weight-regular">
                    <small v-if="sh.seller_name_vendor">
                      {{ ' FROM VENDOR "' + sh.seller_name_vendor }}" : +{{ parseInt(sh.quantity_total_purchase_completed) }} <small class="text-capitalize">from</small> {{ parseInt(sh.quantity_total) }} qty
                    </small>
                    <small v-if="!parseInt(sh.buyer)">
                      <!-- MISSING : DAMAGED/LOST -->
                      {{ ' [MISSING : ' + $StockMissing(parseInt(sh.buyer_none_reason)) + ']' }}
                    </small>
                  </div>
                  <!-- <span v-else-if="!parseInt(sh.seller) && sh.seller_name">
                    {{ ' [FROM : ' + $StockMissing(sh.buyer_none_reason)) + ']' }}
                  </span> -->
                </div>
                <div v-else class="ml-1 font-weight-bold f-width d-flex flex-column">
                  TRX #{{ sh.trx }} = -{{ parseInt(sh.quantity_total) }} qty
                  <small v-if="parseInt(sh.is_merchandise)" class="purple--text text--darken-2">
                    Merchandise
                  </small>
                  <small v-else-if="parseInt(sh.is_additional)" class="indigo--text text--darken-2">
                    Additional Packaging
                  </small>
                  <small v-else-if="!parseInt(sh.product_package_mutation)">
                    Price : {{ (parseInt(sh.price) * parseInt(sh.quantity_total)) | price }} ({{ parseInt(sh.price) | price }}/qty)
                    <!-- Price : {{ (parseInt(sh.price_discount) * parseInt(sh.quantity)) | price }} ({{ parseInt(sh.price_discount) |price }}/qty) -->
                  </small>
                  <small v-else class="orange--text text--darken-2">
                    INNER "{{ sh.package_name }}" : {{ parseInt(sh.quantity_total) }} qty
                  </small>
                  <small v-if="parseInt(sh.buyer) && parseInt(sh.trx) && !parseInt(sh.sync)" class="font-weight-regular" style="font-size: .5rem!important;">
                    Customer : {{ sh.buyer_name }} ({{ sh.buyer_email || sh.buyer_phone }})
                  </small>
                </div>
              </div>
            </div>
            <div class="d-flex align-center">
              <v-chip
                :color="(parseInt(sh.sync) && parseInt(sh.seller) && parseInt(sh.buyer)) ? 'purple darken-2' : !parseInt(sh.sync) ? 'teal darken-2' : (sh.seller_name_vendor ? 'blue darken-1' : (!parseInt(sh.buyer) ? 'red darken-2' : 'orange darken-2'))"
                x-small
                outlined
                dark
                class="text-capitalize"
                style="font-size: 8px;"
              >
                {{ (parseInt(sh.sync) && parseInt(sh.seller) && parseInt(sh.buyer)) ? '#MOVE' : !parseInt(sh.sync) ? '#TRX' : (sh.seller_name_vendor ? '#BUY' : (!parseInt(sh.buyer) ? '#MISSING' : '#SYNC')) }}
              </v-chip>
            </div>
          </div>
          <div v-if="StockHistory.data.length === 12" class="text-center py-4">
            <v-btn depressed x-small color="indigo" dark class="rounded-xl" @click.prevent="ShowHistoryStock(null, null, true)">
              Load More
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let searchInterval = null
let searchAreaTimeout = null
const newMember = {
  id: null,
  name: '',
  area: 0,
  area_name: '',
  birthday: null,
  username: '',
  instagram: '',
  email: '',
  password: '',
  lead: 0,
  province: 0,
  city: 0,
  district: 0,
  address: '',
  lat: '',
  lng: '',
  notes: '',
  role: 'customer',
  isstockist: 1,
  status: 1,
  joined_source: '',
  joined_admin: 0
}
const newProductCategory = {
  id: null,
  nama: '',
  foto: '',
  status: 'aktif'
}
const newProduct = {
  id: null,
  sku: '',
  name: '',
  category: 0,
  description: '',
  how_to_use: '',
  weight: 0,
  price: 0,
  price_hpp: 0,
  galleries: [],
  video: '',
  is_package: 0,
  is_merchandise: 0,
  is_additional: 0,
  stock_limit: 0,
  status: 1
}
const Pricing = {
  id: null,
  user: 0,
  product: 0,
  price_distributor: 0,
  price_agen: 0,
  price_reseller: 0,
  price_customer: 0,
  status: 1
}
export default {
  data: () => ({
    cartDesktopOpen: false,
    desktopFixedCart: true,
    joinedMenu: false,
    joinedDates: [],
    joinedMenuShow: false,
    stockInventoryShow: false,
    cartView: 1,
    newMember: {
      show: false,
      data: null
    },
    togglePassword: false,
    searchLead: '',
    leads: [],
    provinces: [],
    cities: [],
    districts: [],
    cartBuyerQuery: '',
    cartBuyer: 0,
    cartBuyerData: [],
    cartSyncType: 0, // Buy / Pembelian atau Stock Moving
    cartSync: 1, // Sync In or Sync Out
    expandDiscount: 0,
    search: '',
    searchStatus: 1,
    searchCategory: 0,
    newProductCategory: {
      show: false,
      data: null
    },
    newProduct: {
      show: false,
      data: null,
      packageList: [],
      packageListSelected: null
    },
    dialogPricing: {
      show: false,
      productName: '',
      productPrice: 0,
      data: null
    },
    categories: [],
    tableLoading: true,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    options: {},
    headers: [
      // { text: '#ID', value: 'id' },
      {
        text: 'Product',
        value: 'name'
      },
      { text: 'Avg/3m', value: 'avg3month', sortable: false },
      { text: 'Stock / Package', value: 'stock', sortable: false },
      { text: 'Pricing', value: 'buy_price', sortable: false },
      // { text: 'Label', value: 'price', align: 'end' },
      { text: 'Options', value: 'option', sortable: false }
    ],
    dialogGlobalPricing: {
      show: false,
      data: []
    },
    dialogCart: false,
    menuBirthday: false,
    areaquery: '',
    areas: [],
    loadingPricing: false,
    stokistData: [],
    StockHistory: {
      show: false,
      title: '',
      trxOnly: true,
      vendorOnly: false,
      pid: 0,
      page: 1,
      data: []
    },
    historySource: '',
    historyUid: null,
    historyDates: [],
    historyMenu: false,
    historyUserStokist: 0,
    VendorSelected: 0
  }),
  watch: {
    'StockHistory.trxOnly' (v) {
      this.ShowHistoryStock(this.StockHistory.pid)
      if (!v) {
        const stockists = []
        this.$store.state.stokist.map((_stockist) => {
          stockists.push({
            id: _stockist.id,
            name: _stockist.user_name
          })
        })
        this.$store.state.trxSource = this.$store.state.vendor.data.concat(stockists)
      } else { // trx history selected
        this.$store.state.trxSource = this.$store.state.initialTrxSource
      }
    },
    'StockHistory.vendorOnly' (v) {
      this.ShowHistoryStock(this.StockHistory.pid)
    },
    'newProduct.packageListSelected' (v) {
      if (v) {
        const exist = this.newProduct.packageList.find(r => parseInt(r.product_id) === parseInt(v.id))
        if (exist) {
          if (exist.removed) {
            const index = this.newProduct.packageList.findIndex(r => parseInt(r.product_id) === parseInt(v.id))
            if (index > -1) {
              this.newProduct.packageList[index].removed = 0
            }
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'This product already in the list!' })
          }
        } else {
          this.newProduct.packageList.push(Object.assign(v, {
            id: null,
            product_id: parseInt(v.id),
            quantity: parseInt(v.quantity) || 1
          }))
        }
        this.newProduct.packageListSelected = null
      }
    },
    areaquery: function (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    menuBirthday (val) {
      val && setTimeout(() => (this.$refs.pickerBirthday.activePicker = 'YEAR'))
    },
    searchLead: function (v) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        if (this.newMember.data) {
          const searchLead = '&searchLeadFromRole=' + this.newMember.data.role
          this.$store.dispatch('MEMBER_GET', '?page=1&limit=25&q=' + v + searchLead).then((res) => {
            if (res.status) {
              this.leads = res.data.data.data.filter(r => parseInt(r.id) !== parseInt(this.newMember.data.id))
            } else {
              this.leads = []
            }
          })
        }
      }, 300)
    },
    cartBuyerQuery: function (v) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.SEARCH_MEMBER(v)
      }, 300)
    },
    cartBuyer: function (v) {
      this.CART_GET()
    },
    search: function (v) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.PRODUCT_GET(v)
      }, 300)
    },
    searchStatus: function (v) {
      this.PRODUCT_GET(this.search)
    },
    searchCategory: function (v) {
      this.PRODUCT_GET(this.search)
    },
    options: {
      handler () {
        this.PRODUCT_GET(this.search)
      },
      deep: true
    },
    cartView: function (v) {
      if (parseInt(v) === 2 && parseInt(this.$store.state.user.isstockist)) {
        this.cartSyncType = 1
      }
      this.CART_GET()
    },
    historySource () {
      this.ShowHistoryStock()
    },
    historyUid () {
      this.ShowHistoryStock()
    },
    historyDates () {
      this.ShowHistoryStock()
    },
    historyUserStokist () {
      this.ShowHistoryStock()
    }
  },
  computed: {
    historyDateRangeText () {
      return this.historyDates.join(' ~ ')
    },
    itemsLeads () {
      const leads = parseInt(this.newMember.data.lead) && !this.leads.length
        ? [{ id: this.$store.state.user.id, name: this.$store.state.user.name, role: this.$store.state.user.role }].concat([{ id: this.newMember.data.lead, name: this.newMember.data.lead_name, role: this.newMember.data.lead_role }]).concat(this.leads)
        : [{ id: this.$store.state.user.id, name: this.$store.state.user.name, role: this.$store.state.user.role }].concat(this.leads)
      return leads
    }
  },
  created () {
    if (this.$store.state.user.role === 'pusat') {
      this.cartView = 0
      // this.headers = this.headers.filter(r => r.value !== 'quantity_stock_lead' && r.value !== 'buy_price')
      this.$store.dispatch('VENDOR_GET')
    }
    // } else if (this.$store.state.user.role === 'reseller') {
    //   this.headers = this.headers.filter(r => r.value !== 'quantity_stock')
    // }
  },
  mounted () {
    this.searchStatus = 1
    this.searchCategory = 0
    this.PRODUCT_GET('')
    this.CART_GET()
    this.SEARCH_MEMBER('')
    this.PRODUCT_CATEGORY_GET()
    this.$store.dispatch('SHIPPING_GET', '?province_only=true').then((res) => {
      if (res.status) {
        this.provinces = res.data.data
      }
    })
    this.GLOBAL_PRICING_GET()
    this.MEMBER_STOKIST_GET()
    this.$store.dispatch('STOKIST_GET')
  },
  methods: {
    decreaseQuantity (index) {
      const currentQuantity = parseInt(this.newProduct.packageList[index].quantity)
      const newQuantity = currentQuantity > 1 ? currentQuantity - 1 : 1
      this.$set(this.newProduct.packageList, index, {
        ...this.newProduct.packageList[index],
        quantity: newQuantity
      })
    },
    increaseQuantity (index) {
      const currentQuantity = parseInt(this.newProduct.packageList[index].quantity)
      const newQuantity = currentQuantity ? currentQuantity + 1 : 1
      this.$set(this.newProduct.packageList, index, {
        ...this.newProduct.packageList[index],
        quantity: newQuantity
      })
    },
    validateQuantity (index) {
      const quantity = parseInt(this.newProduct.packageList[index].quantity)
      if (!quantity || quantity < 1) {
        this.$set(this.newProduct.packageList, index, {
          ...this.newProduct.packageList[index],
          quantity: 1
        })
      }
    },
    SELECT_VENDOR (v) {
      this.VendorSelected = parseInt(v) || 0
    },
    dragMerchandise (e, productID, isPackage) {
      e.dataTransfer.setData('product-id', productID + ',' + isPackage)
    },
    searchFilter (item, queryText, itemText) {
      const d = (itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      return d || this.searchable(item, queryText)
    },
    searchable (item, q) {
      if (q) {
        const ngrx = new RegExp(q, 'gi')
        return ('0' + item.phone.match(ngrx))
      }
      return true
    },
    LoadPackageList (pid) {
      this.newProduct.packageListSelected = null
      this.newProduct.packageList = []
      this.$store.dispatch('PRODUCT_PACKAGE_GET', pid)
        .then((res) => {
          if (res.status) {
            this.newProduct.packageList = res.data.data || []
          }
        })
    },
    RemovePackageList (PackageIndexOrID) {
      if (parseInt(this.newProduct.data.id)) {
        if (parseInt(this.newProduct.packageList[PackageIndexOrID].id)) {
          this.newProduct.packageList[PackageIndexOrID].removed = 1
        } else {
          this.newProduct.packageList.splice(PackageIndexOrID, 1)
        }
      } else {
        this.newProduct.packageList.splice(PackageIndexOrID, 1)
      }
    },
    LoadProductStokist (pid) {
      this.$store.dispatch('PRODUCT_STOCKIST_GET', pid)
        .then((res) => {
          if (res.status) {
            const getProductTableIndex = this.table.findIndex(r => parseInt(r.id) === parseInt(pid))
            if (getProductTableIndex > -1) {
              this.table[getProductTableIndex].stock_list_stokist = res.data.data || []
            }
          }
        })
    },
    ShowHistoryStock (productID, productName, loadMore) {
      const fromTo = this.GetFromTo(this.historyDates)
      if (!this.StockHistory.show) {
        this.historySource = ''
        this.historyUid = null
      }
      if (productID && typeof productID === 'object') {
        this.StockHistory.pid = productID.id || this.StockHistory.pid
        this.StockHistory.title = productID.name || this.StockHistory.title || '-'
        if (productID.source) {
          const src = productID.source.split(':')[0]
          if (src && src !== 'OTHER') {
            this.historySource = src ? src.trim() : ''
          }
        }
        if (parseInt(productID.user) || parseInt(productID.user) === 0) {
          this.historyUserStokist = parseInt(productID.user) || 0
        }
      } else {
        this.StockHistory.pid = productID || this.StockHistory.pid
        this.StockHistory.title = productName || this.StockHistory.title || '-'
      }
      if (!loadMore) {
        this.StockHistory.page = 1
        this.StockHistory.data = []
        this.StockHistory.show = true
      } else if (this.StockHistory.data.length) {
        this.StockHistory.page += 1
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'There\'s no data to load!' })
        this.StockHistory.show = false
        return false
      }

      const trxOnly = this.StockHistory.trxOnly ? '&trxOnly=1' : ''
      const vendorOnly = this.StockHistory.vendorOnly ? '&vendorOnly=1' : ''

      this.$store.dispatch('PRODUCT_STOCK_HISTORY', {
        pid: parseInt(this.StockHistory.pid),
        q: '?limit=12&page=' + (this.StockHistory.page || 1) + trxOnly + vendorOnly + (this.historySource ? '&source=' + (this.historySource) || '' : '') + (this.historyUid ? '&uid=' + (this.historyUid) || '' : '') + fromTo + (this.historyUserStokist ? ('&user=' + (this.historyUserStokist || 0)) : '')
      })
        .then((res) => {
          if (res.status) {
            if (!loadMore) {
              this.StockHistory.data = res.data.data || []
            } else {
              this.StockHistory.data = this.StockHistory.data.concat(res.data.data)
            }
            // if (!this.StockHistory.data.length) {
            //   this.$store.commit('SNACKBAR', { show: true, text: 'There\'s no data to load!' })
            //   this.StockHistory.show = false
            // }
          }
        })
    },
    MEMBER_STOKIST_GET () {
      this.$store.dispatch('MEMBER_STOKIST_GET').then((res) => {
        if (res.status) {
          this.stokistData = res.data.data || []
        }
      })
    },
    areaSync () {
      const id = parseInt(this.newMember.data.area)
      if (id) {
        const findSelectedArea = this.areas.find(r => parseInt(r.id) === parseInt(id))
        if (findSelectedArea) {
          this.newMember.data.district = parseInt(findSelectedArea.kecamatan_id) || 0
          this.newMember.data.city = parseInt(findSelectedArea.city_id) || 0
          this.newMember.data.province = parseInt(findSelectedArea.province_id) || 0
        }
      }
    },
    areaGet (q, id) {
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '')).then(res => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    SYNC_DATA_DISCOUNT (data) {
      if (data.act === 'add') {
        this.dialogGlobalPricing.data.push(data.data)
      } else if (data.act === 'delete') {
        if (parseInt(data.data.id)) {
          this.$store.dispatch('GLOBAL_PRICING_DELETE', parseInt(data.data.id)).then(res => {
            if (res.status) {
              this.dialogGlobalPricing.data = []
              this.GLOBAL_PRICING_GET()
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
            }
          })
        } else {
          this.dialogGlobalPricing.data.splice(data.index, 1)
        }
      }
    },
    GLOBAL_PRICING () {
      this.dialogGlobalPricing.show = true
      this.dialogGlobalPricing.data = []
      this.GLOBAL_PRICING_GET()
    },
    GLOBAL_PRICING_GET () {
      this.$store.dispatch('GLOBAL_PRICING_GET').then((res) => {
        if (res.status) {
          this.dialogGlobalPricing.data = res.data.data || []
        }
      })
    },
    GLOBAL_PRICING_ADD_BULK () {
      this.$store.dispatch('GLOBAL_PRICING_ADD_BULK', this.dialogGlobalPricing.data).then((res) => {
        if (res.status) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Discount Updated' })
          this.dialogGlobalPricing.show = false
        }
      })
    },
    OPEN_CATEGORY (categoryID) {
      if (categoryID) {
        const findCategory = this.categories.find(r => parseInt(r.id) === parseInt(categoryID))
        this.newProductCategory.data = Object.assign({}, findCategory)
      } else {
        this.newProductCategory.data = Object.assign({}, newProductCategory)
      }
      this.newProductCategory.show = true
    },
    OPEN_DETAIL (product) {
      if (product) {
        this.newProduct.data = Object.assign({}, product)
      } else {
        this.newProduct.data = Object.assign({}, newProduct)
      }
      this.newProduct.packageList = []
      this.newProduct.packageListSelected = null
      if (parseInt(this.newProduct.data.id) && parseInt(this.newProduct.data.is_package)) {
        this.LoadPackageList(this.newProduct.data.id)
      }
      this.newProduct.data.galleries = this.newProduct.data.galleries ? this.$StrToJson(this.newProduct.data.galleries) : []
      this.newProduct.show = true
    },
    PRODUCT_GET (q) {
      const status = this.searchStatus
      const category = this.searchCategory
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('PRODUCT_GET', query + (q ? ('&q=' + q) : '') + (parseInt(category) > 0 ? '&category=' + category : '') + (parseInt(status) === 1 || parseInt(status) === 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          this.table = res.data.data.data.map((r) => {
            r.stock_list_stokist = []
            return r
          })
          this.tableTotal = parseInt(res.data.data.total) || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    PRODUCT_PROCESS (isDelete) {
      const product = Object.assign({}, this.newProduct.data)
      product.galleries = this.$JsonToStr(product.galleries)
      if (isDelete) {
        if (!confirm('Are you sure want to delete this ' + (parseInt(product.is_package) ? 'Package' : 'Product') + ' ?')) {
          return false
        }
        product.status = 100
      }
      if (!product.sku) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input Product SKU' })
        return false
      }
      if (!product.name) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input product name' })
        return false
      }
      if (!product.description) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input product description!' })
        return false
      }
      if (!product.how_to_use && !parseInt(product.is_additional)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input product how to use!' })
        return false
      }
      if (!product.weight) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input product weight' })
        return false
      }
      if (parseInt(product.is_additional)) {
        product.category = 0
      }
      if (parseInt(product.is_package)) {
        product.price_hpp = 0
        if (!this.newProduct.packageList.filter(r => !parseInt(r.removed)).length) {
          this.$store.commit('SNACKBAR', { show: true, text: 'No product package list!' })
          return false
        }
        product.packages = this.newProduct.packageList || []
      }
      if (product.id) {
        this.$store.dispatch('PRODUCT_UPDATE', product).then((res) => {
          if (res.status) {
            this.PRODUCT_GET()
            this.$store.commit('SNACKBAR', { show: true, text: 'Product updated' })
            this.newProduct.show = false
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      } else {
        this.$store.dispatch('PRODUCT_ADD', product).then((res) => {
          if (res.status) {
            this.PRODUCT_GET()
            this.$store.commit('SNACKBAR', { show: true, text: 'Product created' })
            this.newProduct.show = false
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    PRODUCT_CATEGORY_GET () {
      this.$store.dispatch('PRODUCT_CATEGORIES_GET').then((res) => {
        if (res.status) {
          this.categories = res.data.data || []
        }
      })
    },
    PRODUCT_CATEGORY_PROCESS () {
      const productCategory = Object.assign({}, this.newProductCategory.data)
      if (!productCategory.nama) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input category name' })
        return false
      }
      if (productCategory.id) {
        this.$store.dispatch('PRODUCT_CATEGORY_UPDATE', productCategory).then((res) => {
          if (res.status) {
            this.PRODUCT_CATEGORY_GET()
            this.$store.commit('SNACKBAR', { show: true, text: 'Category updated' })
            this.newProductCategory.show = false
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      } else {
        this.$store.dispatch('PRODUCT_CATEGORY_ADD', productCategory).then((res) => {
          if (res.status) {
            this.PRODUCT_CATEGORY_GET()
            this.$store.commit('SNACKBAR', { show: true, text: 'Category created' })
            this.newProductCategory.show = false
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    COUNT_QUANTITY (item, lead) {
      const stockIn = lead ? (parseInt(item.in_lead_quantity) || 0) : (parseInt(item.in_quantity) || 0)
      const stockOut = lead ? (parseInt(item.out_lead_quantity) || 0) : (parseInt(item.out_quantity) || 0)
      return (stockIn - stockOut) || 0
    },
    OPEN_PRICING (item) {
      const price = Object.assign({}, Pricing)
      price.id = parseInt(item.price_id) || null
      price.price_distributor = parseInt(item.price_distributor) || 0
      price.price_agen = parseInt(item.price_agen) || 0
      price.price_reseller = parseInt(item.price_reseller) || 0
      price.price_customer = parseInt(item.price_customer) || 0
      price.product = parseInt(item.id) || 0
      price.user = parseInt(this.$store.state.user.id)
      this.dialogPricing.productName = item.name
      this.dialogPricing.productPrice = parseInt(item.price) || 0
      this.dialogPricing.data = Object.assign({}, price)
      this.dialogPricing.show = true
    },
    PROCESS_PRICING () {
      const price = Object.assign({}, this.dialogPricing.data)
      this.loadingPricing = true
      if (price.id) {
        this.$store.dispatch('PRICING_UPDATE', price).then((res) => {
          if (res.status) {
            this.PRODUCT_GET()
            this.$store.commit('SNACKBAR', { show: true, text: 'Pricing updated' })
            this.dialogPricing.show = false
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
          this.loadingPricing = false
        })
      } else {
        this.$store.dispatch('PRICING_ADD', price).then((res) => {
          if (res.status) {
            this.PRODUCT_GET()
            this.$store.commit('SNACKBAR', { show: true, text: 'Pricing added' })
            this.dialogPricing.show = false
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
          this.loadingPricing = false
        })
      }
    },
    CART_GET () {
      let q = ''
      if (this.cartView === 0) {
        q = q || '?buyer=0&seller=' + parseInt(this.$store.state.user.id) + '&sync=0'
        this.$store.dispatch('CART_GET', (q || ''))
      } else if (this.cartView === 1) {
        q = q || '?buyer=' + parseInt(this.$store.state.user.id) + '&seller=' + parseInt(this.$store.state.user.lead) + '&sync=0'
        this.$store.dispatch('CART_GET', (q || ''))
      } else if (this.cartView === 2) {
        const buyer = this.cartSync === 1 ? parseInt(this.$store.state.user.id) : (this.cartBuyer || 0)
        const seller = this.cartSync === 1 ? (this.cartBuyer || 0) : parseInt(this.$store.state.user.id)
        q = q || '?buyer=' + buyer + '&seller=' + seller + '&sync=1'
        this.$store.dispatch('CART_GET', q)
      }
    },
    CART_PROCESS (cart, product, merchandise, additional) {
      let seller = 0
      let buyer = 0
      if (this.cartView === 0) {
        buyer = 0
        seller = parseInt(this.$store.state.user.id)
      } else if (this.cartView === 1) {
        buyer = parseInt(this.$store.state.user.id)
        seller = parseInt(this.$store.state.user.lead) || 0
      } else if (this.cartView === 2) {
        buyer = this.cartSync === 1 ? parseInt(this.$store.state.user.id) : (this.cartBuyer || 0)
        seller = this.cartSync === 1 ? (this.cartBuyer || 0) : parseInt(this.$store.state.user.id)
      }
      // console.log(this.cartView, seller, buyer, this.cartBuyer)
      const newCart = {
        id: cart ? null : (product.id || null),
        product: cart ? cart.product : product.id,
        trx: 0,
        user: parseInt(this.$store.state.user.id),
        seller: seller,
        buyer: buyer,
        quantity: cart ? cart.quantity || 1 : 1,
        price: 0,
        is_merchandise: merchandise ? 1 : 0,
        is_additional: additional ? 1 : 0,
        sync: parseInt(this.cartView) === 2 ? 1 : 0,
        status: 1
      }
      // if (newItem) {
      //   const cartIndex = this.$store.state.cart.findIndex(r => parseInt(r.product) === parseInt(item.id))
      //   if (cartIndex > -1) {
      //     this.$store.commit('SNACKBAR', { show: true, text: 'Product already on the list' })
      //     return false
      //   }
      // }
      this.$store.dispatch('CART_ADD', newCart).then((res) => {
        if (res.status) {
          this.CART_GET()
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    CART_UPDATE (cart) {
      this.CART_PROCESS(cart)
    },
    SEARCH_MEMBER (q) {
      this.$store.dispatch('MEMBER_ME_GET', ('?q=' + (q || ''))).then((res) => {
        if (res.status) {
          this.cartBuyerData = res.data.data || []
        }
      })
    },
    MEMBER_PROCESS () {
      const member = Object.assign({}, this.newMember.data)
      if (!member.name) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Name is empty' })
        return false
      }
      if (member.role !== 'customer' && member.role !== 'pusat') {
        if (!member.area_name) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Area name is empty' })
          return false
        }
      }
      if (!this.$USERNAME_CHECK(member.username) && member.role !== 'customer') {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid Username' })
        return false
      }
      if (!this.$validateEmail(member.email) && member.role !== 'customer') {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid email' })
        return false
      }
      if (!this.$validatePhone(member.phone)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid phone / whatsapp number' })
        return false
      }
      if (!member.instagram && member.role !== 'customer' && member.role !== 'pusat') {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input instagram account' })
        return false
      }
      if (!parseInt(member.province)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select province' })
        return false
      }
      if (!parseInt(member.city)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select city' })
        return false
      }
      if (!parseInt(member.district)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select district' })
        return false
      }
      if (!member.address) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input address' })
        return false
      }
      if (member.role !== 'customer' && !member.password && !member.id) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Password is empty' })
        return false
      }
      if (member.password && member.role !== 'customer') {
        if (member.password !== member.password_confirm) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Confirm password not match' })
          return false
        }
        if (this.$passwordCheck(member.password) <= 50) {
          if (!confirm('Password is weak, continue ?')) {
            return false
          }
        }
      } else if (member.role === 'customer') {
        member.password = new Date().getUTCMilliseconds()
      }
      if (member.wa_message) {
        member.wa_message = this.$emoticonToString(member.wa_message, this.$store.state.emoticons.emoticons)
      }
      if (parseInt(this.$store.state.user.uid)) {
        member.joined_source = 'WA'
        member.joined_admin = parseInt(this.$store.state.user.uid)
      }
      this.$store.dispatch('MEMBER_ADD', member).then((res) => {
        if (res.status) {
          if (res.data.data === 'username_exist') {
            this.$store.commit('SNACKBAR', { show: true, text: 'Username already taken!' })
          } else if (res.data.data === 'email_exist') {
            this.$store.commit('SNACKBAR', { show: true, text: 'Email already registered. Please change with different email' })
          } else if (res.data.data === 'phone_exist') {
            this.$store.commit('SNACKBAR', { show: true, text: 'Phone number already registered. Please change with different number' })
          } else if (res.data.data === 'email_phone_required') {
            this.$store.commit('SNACKBAR', { show: true, text: 'Please fill email and phone number' })
          } else {
            this.newMember.show = false
            setTimeout(() => {
              if (member.role === 'pusat' && this.cartView === 2) {
                this.MEMBER_STOKIST_GET()
              } else {
                this.SEARCH_MEMBER(member.name || '')
              }
            }, 500)
            this.$store.commit('SNACKBAR', { show: true, text: member.role === 'pusat' ? 'New stokist created' : 'New member created' })
          }
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    loadCities () {
      if (!this.newMember.data) {
        return false
      }
      if (parseInt(this.newMember.data.province)) {
        this.$store.dispatch('SHIPPING_GET', '?province=' + this.newMember.data.province).then((res) => {
          if (res.status) {
            this.cities = res.data.data || []
            this.loadDistricts()
          }
        })
      }
    },
    loadDistricts () {
      if (!this.newMember.data) {
        return false
      }
      if (parseInt(this.newMember.data.city)) {
        this.$store.dispatch('SHIPPING_DISTRICT_GET', '?city=' + this.newMember.data.city).then((res) => {
          if (res.status) {
            this.districts = res.data.data.data || []
          }
        })
      }
    },
    OPEN_DETAIL_MEMBER (stokist) {
      this.newMember.data = Object.assign({}, newMember)
      this.newMember.data.wa_message = this.$stringToEmoticon(this.newMember.data.wa_message, this.$store.state.emoticons.emoticons)
      this.newMember.data.password_confirm = ''
      this.newMember.data.birthday = this.newMember.data.birthday ? this.$localDT(this.newMember.data.birthday, 'datedefault') : null
      this.newMember.data.isstockist = stokist ? 1 : 0
      this.newMember.data.role = stokist ? 'pusat' : 'customer'
      if (this.newMember.data.province) {
        this.loadCities()
      }
      this.newMember.show = true
    },
    GetFromTo (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return ('&from=' + fromTo[0] + '&to=' + fromTo[1])
          } else {
            return (this.search, '&from=' + fromTo[1] + '&to=' + fromTo[0])
          }
        }
      }
      return ''
    },
    UPLOAD_IMAGE (index) {
      const f = document.getElementById('imageUploader')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'imageUploader')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', '.jpg,.jpeg,.png')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e, index)
      })
      document.body.appendChild(el)
      el.click()
    },
    async UPLOAD_HANDLER (event, index) {
      /* eslint-disable */
      const $ = this
      await $.$imgtoBase64(event, async function (res) {
        if (res.status) {
          // $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data
          }).then((resUpload) => {
            if (resUpload) {
              if (index === 'category') {
                $.newProductCategory.data.foto = resUpload.data.data.secure_url
              } else if (parseInt(index) === 0 || parseInt(index) > 0) {
                $.newProduct.data.galleries[parseInt(index)] = resUpload.data.data.secure_url
              } else {
                $.newProduct.data.galleries.push(resUpload.data.data.secure_url)
              }
            } else {
              $.$store.commit('SNACKBAR', { show: true, text: 'Failed upload' })
            }
          })
        } else {
          res.data === 'file_size'
            ? $.$store.commit('SNACKBAR', { show: true, message: 'Ops, file image is too large. Max size (images < 5Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.expand-content {
  .v-expansion-panel-content__wrap {
    padding-bottom: 0px;
  }
  .v-item--active {
    margin-top: 0px;
  }
}
.sell-price {
  min-width: 140px;
}
.cart-tab {
  width: 33.3333%;
  &.sell {
    &.pusat {
      width: 50%;
    }
  }
  &.buy {
    &.pusat {
      display: none;
    }
  }
  &.sync {
    &.pusat {
      width: 50%;
    }
  }
}
.image-galleries {
  >.v-image {
    width: 33.3333%;
    display: inline-flex;
    cursor: pointer;
    opacity: .9;
    transition: all .2s ease;
    &:hover {
      opacity: 1;
    }
    .remove-gallery {
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 50%;
      background: rgba(255, 0, 0, 7);
      color: #fff;
      width: 18px;
      height: 18px;
      line-height: 100%;
      text-align: center;
      padding: 1px 0px;
      cursor: pointer;
    }
  }
}
.append-0 {
  .v-input__append-inner {
    margin-top: 8px!important;
  }
  .v-input__append-outer {
    margin: 0px 0px 0px 10px!important;
    .v-btn {
      &:not(.v-btn--round) {
        &.v-size--default {
          height: 39px!important;
        }
      }
    }
  }
}
.cart-desktop {
  position: relative;
  transition: all .3s ease;
  .toggle-fixed {
    position: absolute;
    background: #f0f0f0;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    top: 50px;
    right: 10px;
    z-index:1;
    width: 24px;
    height: 24px;
    line-height:100%;
  }
  .cart-desktop-fixed {
    position: fixed;
    top: 64px;
    right: 0px;
    padding: 20px 10px;
    height:calc(100% - 64px);
    background: #fff;
    width: 405px;
    .toggle-fixed {
      top: 69px;
      right: 4px;
    }
    .my-order {
      height: calc(100% - 58px)!important;
    }
  }
}
</style>
